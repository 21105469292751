<template>
    <div>
        <header class="main_header">
            <nav class="navbar navbar-expand-lg">
                <div class="container-fluid p-0">
                    <!-- logo -->
                    <div class="logo_box px-3">
                        <router-link class="navbar-brand" to="/"><img :src="navbar_logo" alt="logo" /></router-link>
                    </div>
                    <!-- Toggle Button  -->
                    <button class="navbar-toggler shadow-none" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon">
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 1.25C0 0.918479 0.131696 0.600537 0.366117 0.366117C0.600537 0.131696 0.918479 0 1.25 0H18.75C19.0815 0 19.3995 0.131696 19.6339 0.366117C19.8683 0.600537 20 0.918479 20 1.25C20 1.58152 19.8683 1.89946 19.6339 2.13388C19.3995 2.3683 19.0815 2.5 18.75 2.5H1.25C0.918479 2.5 0.600537 2.3683 0.366117 2.13388C0.131696 1.89946 0 1.58152 0 1.25Z"
                                    fill="white" />
                                <path
                                    d="M0 16.25C0 15.9185 0.131696 15.6005 0.366117 15.3661C0.600537 15.1317 0.918479 15 1.25 15H18.75C19.0815 15 19.3995 15.1317 19.6339 15.3661C19.8683 15.6005 20 15.9185 20 16.25C20 16.5815 19.8683 16.8995 19.6339 17.1339C19.3995 17.3683 19.0815 17.5 18.75 17.5H1.25C0.918479 17.5 0.600537 17.3683 0.366117 17.1339C0.131696 16.8995 0 16.5815 0 16.25Z"
                                    fill="white" />
                                <path
                                    d="M1.25 7.5C0.918479 7.5 0.600537 7.6317 0.366117 7.86612C0.131696 8.10054 0 8.41848 0 8.75C0 9.08152 0.131696 9.39946 0.366117 9.63388C0.600537 9.8683 0.918479 10 1.25 10H11.25C11.5815 10 11.8995 9.8683 12.1339 9.63388C12.3683 9.39946 12.5 9.08152 12.5 8.75C12.5 8.41848 12.3683 8.10054 12.1339 7.86612C11.8995 7.6317 11.5815 7.5 11.25 7.5H1.25Z"
                                    fill="white" />
                            </svg>
                        </span>
                    </button>
                    <!-- Nav bar  -->
                    <div class="collapse navbar-collapse justify-content-end" id="navbarNavDarkDropdown">
                        <ul class="navbar-nav align-items-center">
                            <li  class="nav-item border-end border-secondary px-xl-2 px-0"  @click="closeToggle">
                                <router-link to="/launchpad">{{launch ? 'Launchpad' : "Coming Soon"}}</router-link>
                            </li>

                            <li class="nav-item border-end border-secondary px-xl-2 px-0 "   @click="closeToggle">
                                <router-link class="nav-link" aria-current="page" to="/staking">{{stake ? 'Staking' : "Coming Soon"}}</router-link>

                            </li>
                        


                            <li class="nav-item border-end border-secondary px-xl-2 px-0"  @click="closeToggle">
                                <router-link to="/exchange">{{exchange ? 'Exchange' : "Coming Soon"}}</router-link>
                            </li>
                             <li class="nav-item border-end border-secondary px-xl-2 px-0" @click="closeToggle">
                                <router-link to="/p2p/p2porder"> {{p2p ? 'P2P' : "Coming Soon"}}</router-link>
                            </li>
                            <li class="nav-item border-end border-secondary px-xl-2 px-0" @click="closeToggle" v-if="this.$store.getters.getIsLogin">
                                <router-link to="/portfolio">Portfolio</router-link>
                            </li>
                            <li class="nav-item border-end border-secondary px-xl-2 px-0" @click="closeToggle" v-if="this.$store.getters.getIsLogin">
                                <router-link to="/profile">Settings</router-link>
                            </li>
                          
                           
                            <li class="nav-item  " @click="closeToggle" v-if="!this.$store.getters.getIsLogin">
                                <router-link class="me-2" to="/login">Login</router-link>
                                <router-link class="btn tc-button" to="/register">
                                    Register</router-link>
                            </li>
                            <li class="nav-item " @click="closeToggle" v-else>
                                <button class="btn tc-button py-2" @click="hardLogout">
                                    Logout
                                </button>
                            </li>
                            <!-- <li class="nav-item" @click="closeToggle">
                <router-link class="btn tc-button" to="/register">
                  Register</router-link>
              </li> -->
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</template>

<script>
import ApiClass from "@/api/api";
export default {
    name: "MainNav",
    data() {
        return {
            launch:true,
            stake:true,
            p2p:true,
            exchange:true,
           
            navbar_logo: require("../assets/images/logo.png"),
            loading: false,
        };
    },
    created() {
        // console.log("in nav bar ===", localStorage.getItem("token"));
        localStorage.getItem("token") ?
            this.$store.commit("setIsLogin", true) :
            this.$store.commit("setIsLogin", false);
        // console.log("in nav bar ===", this.$store.getters.getIsLogin);
    },
    methods: {
        closeToggle() {
            const menuToggle = document.getElementById("navbarNavDarkDropdown");
            menuToggle.classList.remove("show");
        },
        hardLogout() {
            // Use sweetalert2
            this.$swal({
                title: "Please Confirm..",
                backdrop: true,
                showCloseButton: true,
                text: "Are you sure you want to logout ?",
                icon: "warning",
                iconColor: "#CF0404",
                showDenyButton: true,
                confirmButtonColor: "#CF0404",
                denyButtonColor: "#CF0404",
                confirmButtonText: "Logout from all devices",
                denyButtonText: "Logout from this devices",
                showLoaderOnConfirm: true,

                // showLoaderOnCancel: true,
            }).then((result) => {

                if (result.isConfirmed) {
                    //   Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    this.logout("hardlogout");
                } else if (result.isDenied) {
                    this.logout("logout");
                }
            });
        },
        async logout(url = null) {
            this.loading = true;
            var resp = await ApiClass.deleteRequest(url, true);

            if (resp?.data) {
                if (resp.data.status_code == 1) {
                    localStorage.clear();
                    this.success(resp.data.message);
                    location.replace("/exchange");
                }
                this.loading = false;
            }
        },
    },
};
</script>

<style>
.navbar-nav a {
  overflow: hidden;
  position: relative;
}

.navbar-nav a::before {
 content: '';
  position: absolute;
  width: 100%;
  left: 0;
}
.navbar-nav a::before {
  background-color: var(--tc-secondary-light-color) !important;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.navbar-nav a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.nav-item{
      transition: all 0.2s ease-in-out;
}




/************************************ MainNav CSS*************************************/
.main_header ul li {
    padding: 5px 25px;
    text-decoration: none;
}

.main_header ul li a {
    text-decoration: none;
    font-size: var(--tc-fs-14);
    color: var(--tc-white);
    padding: 8px 20px;
}

.main_header ul li:hover a {
    color: var(--tc-secondary-color);
    background-color: var(--tc-primary-color);
    padding: 8px 20px;
    border-radius: var(--tc-border-radius);
}

.main_header ul li a.router-link-active.router-link-exact-active {
    color: var(--tc-secondary-color);
    background-color: var(--tc-primary-color);
    padding: 8px 20px;
}

/************************************ Responsive CSS*************************************/

@media all and (min-width: 992px) and (max-width: 1199px) {
    .main_header ul li {
        padding: 5px 16px;
    }
    .main_header ul li a {
    padding: 8px 10px;
}
}

@media all and (min-width: 320px) and (max-width: 991px) {
    .main_header ul {
        background-color: var(--tc-bg-color);
        padding: 15px 0;
    }

    .main_header ul li {
        padding: 10px 25px;
        border-bottom: 2px solid var(--tc-border-color);
        width: 100%;
        text-align: center;
    }
}
</style>
