<template>
<div class="row poster_sections">
    <div class="col-md-12">
        <swiper :autoplay="{ delay: 2500, disableOnInteraction: false,}" :navigation="false" :modules="modules" class="mySwiper" :slides-per-view="3" :loop="true" :space-between="30" @swiper="onSwiper" :spaceBetween="10" :pagination="{clickable: true,}" @slideChange="onSlideChange" :breakpoints="{
      '320': {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      '767': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '1024': {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    }">
            <swiper-slide  class="poster_img" v-if="loading"  ><Skeletor height="100px" width="100px" /></swiper-slide>
            <swiper-slide  class="poster_img" v-else v-for="(poster, index) in posters" :key="index"><a :href="poster.link" target="blank"><img :src="poster.image" alt="banner" class="img-fluid"></a></swiper-slide>
        </swiper>

    </div>
</div>
</template>

<script>

import ApiClass from '@/api/api';

import {
    Swiper,
    SwiperSlide
} from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay"
import "swiper/css/pagination";
import {
    Autoplay,
    Pagination,
    Navigation
} from "swiper";

export default {
    name: 'TaccPosters',
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            posters : [],
            loading :true
        }
    },
     mounted(){
         this.posterGet();
    },
     methods: {
       async posterGet(){
            let response= await ApiClass.getRequest("banner/get",false) ;
            if(response.data.status_code == 1){
                this.loading = false;
                this.posters = response.data.data?response.data.data:[] ;
            }
         }
     },
    setup() {
        return {
            modules: [Autoplay, Pagination, Navigation],
        }

    },

}
</script>

<style>
/************************************ Poster CSS*************************************/


.poster_sections .swiper {
    overflow-x: hidden !important;
    overflow-y: visible !important;
    min-height: 200px !important;
    text-align: center;
}

.poster_sections .swiper-pagination-bullet {
    background: var(--tc-secondary-color) !important;
}
/************************************ Poster CSS*************************************/
@media all and (min-width:320px) and (max-width:767px){
    /* .poster_img img{
    max-width:280px;
    height: auto;
    object-fit: cover;
} */
}
</style>
