<template>
  <div>
    <footer class="main_footer">
      <div class="container">
        <div class="row">
          <!-- About  -->
          <div class="col-sm-6 col-md-4 col-lg-2 offset-lg-1">
            <div class="footer_box">
              <h5 class="m-0 mb-3">About</h5>
              <ul class="list-group justify-content-center">
                <li class="list-group-item bg-transparent border-0 ps-0" v-for="(footer_link, index) in about_links" :key="index"><router-link :to="footer_link.url">{{footer_link.title}}</router-link></li>
              </ul>
            </div>
          </div>
          <!-- Services  -->
          <div class="col-sm-6 col-md-4 col-lg-2 offset-lg-1 mt-4 mt-md-0">
            <div class="footer_box">
              <h5 class="m-0 mb-3">Services</h5>
              <ul class="list-group justify-content-center">
                <li class="list-group-item bg-transparent border-0 ps-0" v-for="(footer_link, index) in services_links" :key="index"><router-link :to="footer_link.url">{{footer_link.title}}</router-link></li>
              </ul>
            </div>
          </div>
          <!-- Support  -->
          <div class="col-sm-6 col-md-4 col-lg-2 offset-lg-1 mt-4 mt-md-0">
            <div class="footer_box">
              <h5 class="m-0 mb-3">Support</h5>
              <ul class="list-group justify-content-center">
                <li class="list-group-item bg-transparent border-0 ps-0" v-for="(footer_link, index) in support_links" :key="index"><router-link :to="footer_link.url">{{footer_link.title}}</router-link></li>
              </ul>
            </div>
          </div>
          <!-- Buy Crypto  -->
          <div class="col-sm-6 col-md-4 col-lg-2 offset-lg-1 mt-4 mt-md-0">
            <div class="footer_box">
              <h5 class="m-0 mb-3">Buy Crypto</h5>
              <ul class="list-group">
                <li class="list-group-item bg-transparent border-0 ps-0" v-for="(footer_link, index) in buy_links" :key="index"><router-link :to="footer_link.url">{{footer_link.title}}</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="footer_box mt-4">
              <p class="m-0 text-center">&copy; 2022 <router-link to="/">Abundance</router-link>. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
    import exchange_data from '@/assets/json/exchange_data.json';
    export default {
        name: 'MainFooter',
        data() {
            return {
                about_links: exchange_data.about_links,
                services_links: exchange_data.services_links,
                support_links: exchange_data.support_links,
                buy_links: exchange_data.buy_links,

            }
        }
    }
</script>

<style scoped>
    /************************************ Footer CSS*************************************/


    .main_footer a {
  overflow: hidden;
  position: relative;
}

.main_footer a::before {
 content: '';
  position: absolute;
  width: 100%;
  left: 0;
}
.main_footer a::before {
  background-color: var(--tc-secondary-light-color) !important;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.main_footer a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}


    
    footer.main_footer {
        background-image: url(../assets/images/footer.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 40px 0;
    }
    
    .footer_box h5 {
        color: var(--tc-white);
        text-transform: uppercase;
        font-size: var(--tc-fs-16);
        font-family: var(--tc-ff-bold);
    }
    
    .footer_box ul li a {
        color: var(--tc-grey);
        font-size: var(--tc-fs-14);
        text-decoration: none;
    }
    
    .footer_box ul li a:hover {
        color: var(--tc-grey);
        font-size: var(--tc-fs-14);
        text-decoration: none;
        color: var(--tc-secondary-color);
    }
    
    
    /************************************ Copyright CSS*************************************/
    
    .footer_box p {
        font-size: var(--tc-fs-14);
        color: var(--tc-white);
    }
    
    .footer_box p a {
        color: var(--tc-secondary);
        text-decoration: none;
    }
    
    .footer_box p a:hover {
        text-decoration: none;
        color: var(--tc-secondary-color);
    }
</style>