<template>
<div>
    <div class="why_tacc_box">
        <div class="why_tacc_icon">
            <img :src="require(`@/assets/${why_tacc.icon}`)" alt="icon">
        </div>
        <div class="why_tacc_content text-center mt-3">
            <h5>{{why_tacc.title}}</h5>
            <p>{{why_tacc.des}}</p>
        </div>
    </div>
</div>
</template>

<script>
import exchange_data from '@/assets/json/exchange_data'
export default {
    name: 'WhyTacc',
    props: {
        why_tacc: Object
    }

}
</script>

<style scoped>
/************************************ Why Tacc Component CSS*************************************/

.why_tacc_box {
    position: relative;
    margin-top: 50px;
    z-index: 9;
}

.why_tacc_box::before {
    position: absolute;
    content: '';
    top: 0px;
    bottom: 0;
    margin: auto 0;
    left: 0;
    background-image: url(../../assets/images/landing_page/why_border.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 319px;
    z-index: -1;
}

.why_tacc_icon {
    width: 120px;
    height: 120px;
    border: 2px solid var(--tc-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
}

.why_tacc_content h5 {
    color: var(--tc-white);
}

.why_tacc_content p {
    color: var(--tc-grey);
    font-family: var(--tc-ff-medium);
    max-width: 68%;
    margin: auto;
}

/************************************ Why Tacc Component CSS*************************************/
@media all and (min-width:992px) and (max-width:1199px) {
    .why_tacc_box {
        margin-bottom: 70px;
    }

    .why_tacc_content p {

        max-width: 55%;
    }
}

@media all and (min-width:768px) and (max-width:991px) {
    .why_tacc_box {
        margin-bottom: 70px;
    }

    .why_tacc_content p {

        max-width: 60%;
    }
}

@media all and (min-width:320px) and (max-width:767px) {

    .why_tacc_box::before {
        display: none;
    }

    .why_tacc_box {
        border: 2px solid var(--tc-secondary-color);
        padding: 25px;
        margin: 25px 0;
    }

    .why_tacc_content p {
        max-width: 100%;
    }

}
</style>
