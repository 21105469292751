import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    plugins: [createPersistedState()],
    state: {
        user: null,
        isLogin: false,
        favourite: [],
        market_currency: null,
        price: {},
        editOrderInfo: null,
        ticketData: null,
        newstate: false,
        otp_data: {},
        matchData: {},
        stakeData:{},
        stakeRoute: 0,
        lachData: null,
        show_upi: null,
        payment_name: null,
    },
    getters: {
        getIsLogin: state => {
            return state.isLogin;
        },
        favouriteCryptos: state => {
            return state.favourite;
        },
        getToken() {
            return localStorage.getItem('token');
        },
        getUser: state => {
            return state.user;
        },
        getMarketCurrency: state => {
            return state.market_currency;
        },
        getPrice: state => {
            return state.price;
        },
        getEditInfo: state => {
            return state.editOrderInfo;
        },
        getTicketData: state => {
            return state.ticketData;
        },
        getnewstate: state => {
            return state.newstate;
        },
        getOtpData: state => {
            return state.otp_data;
        },
        getmatchData(state) {
            return state.matchData
        },
        getStakeData(state) {
            return state.stakeData
        },
        getsatkeHis(state) {
            return state.stakeRoute;
          },
        getLunData(state) {
            return state.lachData;
          },
          getshow_upi(state) {
            return state.show_upi;
          },
          getpayment_name(state) {
            return state.payment_name;
          },

    },
    mutations: {
        setIsLogin(state, value) {
            state.isLogin = value;
        },
        SET_FAVOURITE(state, val) {
            state.favourite = val;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_Market_Currency(state, payload) {
            state.market_currency = payload;
        },
        SET_Price(state, prices) {
            state.price = prices;
        },
        setEditOrderInfo(state, payload) {
            state.editOrderInfo = payload;
        },
        SET_TicketData(state, payload) {
            state.ticketData = payload;
        },
        SET_Newstate(state, payload) {
            state.newstate = payload;
        },
        SET_OTP_DATA(state, payload) {
            state.otp_data = payload;
        },
        setmatchData(state, payload) {
            state.matchData = payload
        },
        setStakeData(state, payload) {
            state.stakeData = payload
        },
        stakeHistory(state, payload) {
            state.stakeRoute = payload;
          },
        lcnPdData(state, payload) {
            state.lachData = payload;
          },
          setshow_upi(state, payload) {
            state.show_upi = payload
          },
          setpayment_name(state, payload) {
            state.payment_name = payload
          },
    },
    actions: {

        updateMatchdata({ commit }, payload) {
            commit('setmatchData', payload);
        },
    },

    modules: {}
})