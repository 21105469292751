<template>
<MainNav />
<router-view />
<MainFooter />
</template>

<script>
import MainNav from "@/components/MainNav.vue";
import MainFooter from "@/components/MainFooter";
export default {
    name: "App",

    components: {
        MainNav,
        MainFooter,
    },
    data() {
        return {
            SweetAlert: "",

        };
    },
    // watch: {
    //   $route: function () {
    //     console.log('Here');
    //     this.test = this.test + 1;
    //   },
    // },
};
</script>

<style>
/*****************skeltor loader css */

.vue-skeletor {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.12);
}

.vue-skeletor:not(.vue-skeletor--shimmerless):after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(37, 22, 22, 0));
    animation: shimmer 1.5s infinite;
    content: '';
}

.vue-skeletor--rect,
.vue-skeletor--circle {
    display: block;
}

.vue-skeletor--circle {
    border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
    border-radius: 9999px;
}

.vue-skeletor--text {
    line-height: 1;
    display: inline-block;
    width: 100%;
    height: inherit;
    vertical-align: middle;
    top: -1px;
}
 

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

/*****************skeltor loader css */
/************************************ Font Family *************************************/

@font-face {
    font-family: Montserrat-Bold;
    src: url(../src/assets/font-family/Montserrat-Bold.ttf);
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url(../src/assets/font-family/Montserrat-SemiBold.ttf);
}

@font-face {
    font-family: Montserrat-Medium;
    src: url(../src/assets/font-family/Montserrat-Medium.ttf);
}

@font-face {
    font-family: Montserrat-Regular;
    src: url(../src/assets/font-family/Montserrat-Regular.ttf);
}

/************************************ Variables *************************************/

:root {
    /*==============Colors==============*/
    --tc-bg-color: #121216;
    --tc-primary-color: #222225;
    --tc-bg-grey: #3e3e44;
    --tc-secondary-color: #ff9100;
    --tc-secondary-light-color: #ff9100d0;
    --tc-white: #fff;
    --tc-black: #000;
    --tc-transparent: rgba(0, 0, 0, 0);
    --tc-border-color: rgba(255 255 255 / 4%);
    --tc-border-exchange: rgba(255 255 255 / 47%);
    --tc-grey: #ffffffbf;
    --tc-red: #ff5454;
    --tc-light-red: rgba(255, 57, 0, 0.2);
    --tc-green: #47ab40;
    --tc-light-green: rgba(71, 171, 64, 0.2);
    /*==============Font-Size==============*/
    --tc-fs-8: 8px;
    --tc-fs-10: 10px;
    --tc-fs-12: 12px;
    --tc-fs-14: 14px;
    --tc-fs-16: 16px;
    /*==============Shadows==============*/
    --tc-shadow-1: 0px 6px 20px rgba(255, 255, 255, 0.06),
        inset -2px -1px 4px rgba(255, 255, 255, 0.09),
        inset 1px 1px 4px rgba(255, 255, 255, 0.21);
    --tc-shadow-2: 0px 4px 4px #141313;
    --tc-shadow-3: -6px 4px 39px rgba(0, 0, 0, 0.42);
    /*==============Font-Family==============*/
    --tc-ff-bold: Montserrat-Bold;
    --tc-ff-semi-bold: Montserrat-SemiBold;
    --tc-ff-medium: Montserrat-Medium;
    --tc-ff-regular: Montserrat-Regular;
    /*==============Border-Radius==============*/
    --tc-border-radius: 3px;
    --tc-br-exchange: 5px 5px 0px 0px;
    --tc-light-grey:#ffffff2b;
    --tc-hover-tr:#363636;
    --p2p-blue:hsl(233, 100%, 63%);
    --modal-wallet:#22252c;
}

#app {
    background-color: var(--tc-bg-color);
    min-height: 100vh;
    font-family: var(--tc-ff-semi-bold);
}

/************************************ Buttons *************************************/

.tc-button {
    background-color: var(--tc-secondary-color) !important;
    border-color: var(--tc-secondary-color);
    color: var(--tc-white) !important;
    font-size: var(--tc-fs-14) !important;
    box-shadow: var(--tc-shadow-1) !important;
    border-radius: var(--tc-border-radius);
    font-family: var(--tc-ff-medium);
}

.tc-button:hover {
    background-color: var(--tc-secondary-light-color) !important;
    border-color: var(--tc-secondary-color) !important;
    color: var(--tc-white) !important;
    box-shadow: none !important;
}

.dark-button {
    background: var(--tc-bg-grey) !important;
    border-color: var(--tc-bg-grey) !important;
    box-shadow: var(--tc-shadow-1) !important;
    font-size: var(--tc-fs-14) !important;
    border-radius: var(--tc-border-radius);
    font-family: var(--tc-ff-medium) !important;
    color: var(--tc-white) !important;
}

.dark-button:hover {
    background: var(--tc-primary-color) !important;
    border-color: var(--tc-primary-color) !important;
    box-shadow: var(--tc-shadow-1) !important;
    border-radius: var(--tc-border-radius) !important;
    color: var(--tc-white) !important;
}

/************************************ Common CSS *************************************/

.thead-sticky {
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 9;
}

.text-green {
    color: var(--tc-green);
}

.text-red {
    color: var(--tc-red);
}
.text-yellow{
  color: var(--tc-secondary-color);
}

/************************************Main Custom Scroll *************************************/
/* width */
html::-webkit-scrollbar {
    width: 8px;
}

/* Track */
html::-webkit-scrollbar-track {
    background: var(--tc-white);
}

/* Handle */
html::-webkit-scrollbar-thumb {
    background: var(--tc-bg-color);
}

/* Handle on hover */
html::-webkit-scrollbar-thumb:hover {
    background: var(--tc-bg-grey);
}
/* pagination */
p.VuePagination__count.VuePagination__count{
    font-family: var(   --tc-ff-medium);
}
</style>
