<template>
<div>
    <div class="buy_Sell_tab">
        <div class="tabs_box mb-5">
            <ul class="list-group list-group-horizontal nav-fill mb-4">
                <li class="list-group-item bg-transparent flex-grow-1 border-0" v-for="(tab, index) in tabs" :key="index"><button @click="showTab(tab)" :class="activeTab == tab ? 'active' : '' ">{{tab}}</button></li>

            </ul>
        </div>
        <!-- tabs_box  -->

        <div class="buy_Sell_content">

            <div class="row buy_Sell_cus">
                <div class="col-md-4">
                    <div class="buyer_process_box mb-5 mb-md-0">
                        <div class="buyer_process_img">
                            <img :src="tabData.buy_sell_image" alt="icon">
                        </div>
                        <div class="buyer_sell_content_box">
                            <h6>{{tabData.buy_sell_heading}}</h6>
                            <p>{{tabData.buy_sell_des}}</p>
                        </div>
                    </div>
                </div>
                <!-- place order  -->
                <div class="col-md-4">
                    <div class="buyer_process_box mb-5 mb-md-0">
                        <div class="buyer_process_img">
                            <img :src="tabData.send_receive_image" alt="icon">
                        </div>
                        <div class="buyer_sell_content_box">
                            <h6>{{tabData.send_receive_heading}}</h6>
                            <p>{{tabData.send_receive_des}}</p>
                        </div>
                    </div>
                </div>
                <!-- Payment send receive  -->
                <div class="col-md-4">
                    <div class="buyer_process_box">
                        <div class="buyer_process_img">
                            <img :src="tabData.wallet_image" alt="icon">
                        </div>
                        <div class="buyer_sell_content_box">
                            <h6>{{tabData.wallet_heading}}</h6>
                            <p>{{tabData.wallet_des}}</p>
                        </div>
                    </div>
                </div>
                <!-- Payment send receive  -->
            </div>
        </div>
        <!-- tabs_content  -->

    </div>
</div>
</template>

<script>
export default {
    name: 'P2pWorking',

    data() {
        return {
            isActive: true,
            activeTab: '',
            tabs: ["Buyer", "Seller"],
            tabData: [],
            buy_data: {

                buy_sell_image: require('../../assets/images/landing_page/p2p-1.png'),
                buy_sell_heading: "PLACE AN ORDER",
                buy_sell_des: "You’ll be auto-matched with a seller instantly.",
                send_receive_image: require('../../assets/images/landing_page/p2p-2.png'),
                send_receive_heading: "PAY DIRECTLY TO SELLER",
                send_receive_des: "The seller will confirm your payment.",
                wallet_image: require('../../assets/images/landing_page/p2p-3.png'),
                wallet_heading: "TACC RELEASES THE ESCROWED USDT",
                wallet_des: "It makes Tacc P2P a safe and fast method to convert fiat to crypto."

            },
            sell_data: {

                buy_sell_image: require('../../assets/images/landing_page/p2p-1.png'),
                buy_sell_heading: "PLACE AN ORDER",
                buy_sell_des: "You’ll be auto-matched with a buyer instantly.",
                send_receive_image: require('../../assets/images/landing_page/p2p-4.png'),
                send_receive_heading: "CONFIRM RECEIVING THE PAYMENT",
                send_receive_des: "Tacc will transfer the USDT to the buyer only after you acknowledge receiving payment.",
                wallet_image: require('../../assets/images/landing_page/p2p-3.png'),
                wallet_heading: "TACC RELEASES THE ESCROWED USDT",
                wallet_des: "It makes Tacc P2P a safe and fast method to convert crypto to fiat."
            }
        }
    },

    mounted() {
        this.tabData = this.buy_data;
        this.showTab("Buyer");

    },
    methods: {
        showTab(tab) {
            this.activeTab = tab
            if (tab == "Buyer") {
                this.tabData = this.buy_data
            }
            if (tab == "Seller") {
                this.tabData = this.sell_data
            }

        }
    }

}
</script>

<style scoped>
/************************************ P2P Working Component CSS*************************************/
.active {
    border-bottom: 2px solid var(--tc-secondary-color);
    background-color: var(--tc-transparent);
    color: var(--tc-white);
}

button {
    border: 0;
    background-color: var(--tc-transparent);
    color: var(--tc-grey);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    border: 2px solid var(--tc-transparent);
    width: 100%;
}

.buyer_process_img {
    text-align: center;
    position: relative;
    z-index: 9;
}

.buyer_process_img::before {
    position: absolute;
    content: '';
    top: -25px;
    right: -50px;
    left: 0;
    margin: 0 auto;
    background-color: #3A3B53;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: -1;
}

.buyer_sell_content_box {
    text-align: center;
    margin-top: 35px;
}

.buyer_sell_content_box h6 {
    color: var(--tc-white);
    font-family: var(--tc-ff-medium);
}

.buyer_sell_content_box p {
    color: var(--tc-grey);
    font-family: var(--tc-ff-regular);
}

.buyer_process_box {
    transition: all 1s ease;

}

.buyer_process_box:hover {
    transition: all 1s ease;
    cursor: pointer;

}

.buyer_process_box:hover .buyer_process_img {
    box-shadow: var(--tc-shadow2);
    transition: all 1s ease;
}

/************************************ Media CSS*************************************/

@media all and (min-width:768px) and (max-width:991px) {
    .buyer_sell_content_box h6 {

        font-size: 16px;
    }

}
</style>
