<template>
<div class="main_heading_box mb-5">
    <h2>{{big_heading}}</h2>
    <h6>{{small_heading}}</h6>
</div>
</template>

<script>
export default {
    name: 'MainHeading',
    props: {
        big_heading: String,
        small_heading: String
    }

}
</script>

<style scoped>
/************************************ Main Heading CSS*************************************/
.main_heading_box h2 {
    color: var(--tc-transparent);
    font-family: var(--tc-ff-semi-bold);
    text-transform: uppercase;
    font-size: 74px;
    position: relative;
    top: 16px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--tc-secondary-color);
}

.main_heading_box h6 {
    color: var(--tc-black);
    font-family: var(--tc-ff-semi-bold);
    font-size: 24px;
    text-transform: uppercase;
}

section.why_tacc .main_heading_box h6,
section.p2p_tacc .main_heading_box h6,
section.get_in_touch h6,
section.app_download_tacc h6 {
    color: var(--tc-white);
}

/************************************ Media CSS*************************************/

@media all and (min-width:768px) and (max-width:1024px) {
    .main_heading_box h2 {
        font-size: 50px;
        top: 18px;
    }
}

@media all and (min-width:320px) and (max-width:767px) {
    .main_heading_box h2 {
        font-size: 40px;
        top: 15px;
    }

    .main_heading_box h6 {

        font-size: 18px;
    }
}
</style>
