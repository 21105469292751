<template>
<div class="row live_chart">
    <div class="col-md-12">
        <swiper :autoplay="{ delay: 1500, disableOnInteraction: false,}" :navigation="true" :modules="modules" class="mySwiper" :slides-per-view="4" :loop="true" :space-between="30" @swiper="onSwiper" :spaceBetween="10" :pagination="{clickable: true,}" @slideChange="onSlideChange" :centerInsufficientSlides="true" :breakpoints="{
      '320': {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      '479': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '767': {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      '991': {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      '1199': {
        slidesPerView: 5,
        spaceBetween: 20,
      },
    }">
        <swiper-slide class="live_chart_box" v-if="loading">
                <ul class="list-group list-group-horizontal justify-content-between align-items-center">
                    <li class="list-group-item border-0 px-0">
                        <span class="fs-5"><Skeletor width="100px"/></span>
                    </li>
                    <li class="list-group-item border-0 px-0">
                        <span class="change_price"><Skeletor width="50px"/></span>
                    </li>
                </ul>
                <p class="m-0 my-2"><Skeletor/></p>
            </swiper-slide>

            <swiper-slide v-else class="live_chart_box" v-for="(chart, index) in data" :key="index">
                <ul class="list-group list-group-horizontal justify-content-between align-items-center">
                    <li class="list-group-item border-0 px-0">
                        <span class="fs-5">{{chart.currency}}</span>
                    </li>
                    <li class="list-group-item border-0 px-0">
                        <span class="change_price" :style="chart.change > 0 ? 'background-color:var(--tc-green)' : 'background-color:var(--tc-red)'">{{parseFloat(chart.change).toFixed(2)}} %</span>
                    </li>
                </ul>
                <p class="m-0 my-2">&dollar; {{parseFloat(chart.price)}}</p>
            </swiper-slide>
        </swiper>

    </div>
</div>
</template>

<script>
//Site Key ==  6LdJLWofAAAAAGQA6rvaIfkOSAfuo1H2OsJZG2vK
// Secret Key = 6LdJLWofAAAAACwOgo6UDgNUBCvPauUZgUUYPsWH

import {
    Swiper,
    SwiperSlide
} from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay"
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
    Autoplay,
    Pagination,
    Navigation
} from "swiper";

export default {
    name: 'LiveChart',
    components: {
        Swiper,
        SwiperSlide,
    },
     props: {
    data: Array,
    loading: Boolean
  },
    data() {
        return {
        
        }
    },
    setup() {
        
        return {
            modules: [Autoplay, Pagination, Navigation],
            
        }

    },

}
</script>

<style>
/************************************ Live Chart CSS*************************************/
.live_chart_box {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.30);
    border: 2px solid var(--tc-secondary-color);
    border-radius: 6px;
    padding: 10px 20px;
}

.live_chart .swiper-button-next:after,
.live_chart .swiper-button-prev:after {
    font-size: 10px !important;
    line-height: 1 !important;
    color: var(--tc-white) !important;
    background-color: var(--tc-secondary-color) !important;
    padding: 4px 5px !important;
    border-radius: 2px !important;
}

.live_chart .swiper-button-next {

    top: 36% !important;
    right: -4px !important;
}

.live_chart .swiper-button-prev {
    top: 36% !important;
    left: -4px !important;
}

.change_price {
    padding: 3px 4px;
    border-radius: 3px;
    color: var(--tc-white);
    font-size: var(--tc-fs-14);
}

.live_chart_box p {
    font-family: var(--tc-ff-medium);
    font-size: 18px;
}

.live_chart .swiper {
    overflow-x: hidden !important;
    overflow-y: visible !important;
    min-height: 160px !important;
    text-align: center;
}

.live_chart .swiper-pagination-bullet {
    background: var(--tc-secondary-color) !important;
}

/************************************ Live Chart CSS*************************************/
@media all and (min-width:320px) and (max-width:767px) {
    /* .poster_img img{
    max-width:280px;
    height: auto;
    object-fit: cover;
} */
}
</style>
