<template>
<div class="tacc_features_box">
    <div class="feature_upper_box d-flex align-items-center flex-column flex-md-row">
        <div class="feature_icon mb-3 mb-md-0">
            <img :src="require(`@/assets/${features_tacc.icon}`)" alt="icon">
        </div>
        <div class="feature_heading text-center flex-grow-1">
            <h5>{{features_tacc.title}}</h5>
        </div>
    </div>
    <div class="features_des text-center text-md-start">
        <p class="m-0">{{features_tacc.des}}</p>
    </div>
</div>
</template>

<script>
export default {
    name: 'FeatureTacc',
    props: {
        features_tacc: Object
    }
}
</script>

<style>
/************************************ Tacc Features Component CSS*************************************/
.tacc_features_box {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background-color: var(--tc-white);
    overflow: hidden;
    margin-bottom: 20px;
}

.feature_icon {
    background-image: linear-gradient(to right, #5A3000, #2B1702);
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature_heading h5 {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: #5A3000;
    text-decoration-thickness: 2px;
    text-underline-offset: 10px;
}

.features_des {
    padding: 30px 42px 20px;
}

.features_des p {
    font-family: var(--tc-ff-regular);
    font-size: 15px;
}

.feature_heading h5 {
    color: #141414;
}

/************************************ Media CSS*************************************/
@media all and (min-width:768px) and (max-width:991px) {
    .feature_heading h5 {
        font-size: 16px;
    }

    .features_des p {
        font-size: var(--tc-fs-14);
    }
}

@media all and (min-width:320px) and (max-width:767px) {
    .feature_heading h5 {
        font-size: 17px;
    }
}
</style>
