<template>
<div class="benefits_p2p_box">
    <div class="benefits_p2p_icon text-center">
        <img :src="require(`@/assets/${p2p_benefits.icon}`)" alt="icon">
    </div>
    <div class="benefits_p2p_heading text-center">
        <h5>{{p2p_benefits.title}}</h5>
    </div>
    <div class="benefits_p2p_des text-center">
        <p>{{p2p_benefits.des_1}}</p>
        <p>{{p2p_benefits.des_2}}</p>
    </div>
</div>
</template>

<script>
export default {
    name: "BenefitsP2p",
    props: {
        p2p_benefits: Object
    }
}
</script>

<style scoped>
/************************************ P2P Benefits Component CSS*************************************/
.benefits_p2p_box {
    box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    z-index: 1;
    background-color: var(--tc-white);
    background-image: url(../../assets/images/landing_page/p2p_shape.png);
    background-position: right top;
    background-repeat: no-repeat;
    padding: 20px;
    margin: 25px 22px;

}

.benefits_p2p_box:before {
    position: absolute;
    content: '';
    top: -5%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #2E1D0A;
    width: 90%;
    height: 5%;
    border-radius: 5px 5px 0 0;

}

.benefits_p2p_box:after {
    position: absolute;
    content: '';
    bottom: -5%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #2E1D0A;
    width: 90%;
    height: 5%;
    border-radius: 0 0 5px 5px;

}

.benefits_p2p_icon img {
    position: relative;
    z-index: 1;
}

.benefits_p2p_heading h5 {
    color: #141414;
    margin: 20px 0 20px;
    position: relative;
    z-index: 1;
}

.benefits_p2p_des p {
    font-family: var(--tc-ff-regular);
    position: relative;
    z-index: 1;
    margin: 0;
    margin-bottom: 20px;

}

/************************************ Media CSS*************************************/
@media all and (min-width:768px) and (max-width:1399px) {
    .benefits_p2p_box {
        min-height: 350px;

    }

}

@media all and (min-width:320px) and (max-width:767px) {
    .benefits_p2p_box {
        min-height: 350px;

    }
}
</style>
