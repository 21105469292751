<template>
<div class="get_touch_box mb-3 mb-md-0">
    <div class="get_touch_icon">
        <img :src="require(`@/assets/${get_touch.image}`)" alt="icon">
    </div>
    <div class="get_touch_content mt-3">
        <h5 class="m-0 mb-1">{{get_touch.title}}</h5>
        <p>{{get_touch.des}}
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: 'GetTouch',
    props: {
        get_touch: Object
    }
}
</script>

<style scoped>
/************************************ GetTouch CSS*************************************/
.get_touch_box {
    border: 2px solid var(--tc-white);
    text-align: center;
    padding: 20px;
}

.get_touch_content h5 {
    color: var(--tc-white)
}

.get_touch_content p {
    color: var(--tc-grey);
    font-family: var(--tc-ff-regular);
    font-size: var(--tc-fs-14);
    margin: auto;

}

/************************************ Media CSS*************************************/
@media all and (min-width:768px) and (max-width:1399px) {
    .get_touch_box {
        min-height: 270px;
    }
}

@media all and (min-width:320px) and (max-width:767px) {
    .get_touch_box {
        min-height: 260px;
    }
}
</style>
