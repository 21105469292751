<template>
  <div class="trade_tacc_box">
      <div class="trade_tacc_img text-center">
          <img :src="require(`@/assets/${trading_tacc.image}`)" class="img-fluid" alt="img">
      </div>
      <div class="trade_tacc_content my-3 my-lg-0">
          <div class="border_box text-center">
              <h6 class="m-0 mb-2 ">{{trading_tacc.title}}</h6>
          <p class="m-0">{{trading_tacc.des}}</p>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'TradeTacc',
    props:{
        trading_tacc:Object
    }

}
</script>

<style scoped>
/************************************ Trade Tacc CSS*************************************/
.trade_tacc_img{
    position: relative;
    z-index: 9;
}
.trade_tacc_content{
box-shadow:0 3px 6px rgba(0, 0, 0, 0.16);
padding-left:25px;
margin-top: 20px;
position: relative;
z-index: 9;

}
.border_box{
border-left: 4px solid var(--tc-secondary-color);
padding: 40px 20px;
background-color: var(--tc-white);

}
.border_box p{
    color:#6D6D6D;
    font-size: var(--tc-fs-14);
}
/************************************ Media CSS*************************************/
</style>